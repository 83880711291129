/* eslint-disable react-hooks/rules-of-hooks */
import { Fragment, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Typeahead, Hint } from "react-bootstrap-typeahead";
import set from "lodash/set";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import kebabCase from "lodash/kebabCase";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { ReactComponent as IconPlusCircle } from 'bootstrap-icons/icons/plus-circle.svg'
import { ReactComponent as IconDashCircle } from 'bootstrap-icons/icons/dash-circle.svg'

import useFilters from "../../../hooks/useFilters";
import useQuery from "../../../hooks/useQuery";
import useMutation from "../../../hooks/useMutation";
import getModal from "../../../lib/getModal";
import Text from "../../Form/Text";
import useStore from "../useStore";
import Select from "../../Form/Select";
import { formatDate } from "../../../config/i18n";
import _onChangeConsumer from "../../Consumers/onChangeConsumer";
import styles from "./styles.module.scss";
import Locations from '../Filters/Locations'
import Resources from '../Filters/Resources'
import Services from '../Filters/Services'
import Financiers from '../Filters/Financiers'
import validateConsumer from "../../Consumers/validateConsumer"
import useUser from "hooks/useUser"

const { REACT_APP_MEET_URL } = process.env;

const renderInput = ({ hint, isInvalid } = {}) => ({
  inputRef,
  referenceElementRef,
  ...inputProps
}) => (
  <Hint
    shouldSelect={(shouldSelect, e) =>
      ["Enter", "Tab"].includes(e.key) || shouldSelect
    }
  >
    <div className="flex-fill w-100">
      <input
        {...inputProps}
        type="text"
        autoComplete="--disabled"
        autoComplete="none"
        autoComplete="off"
        className={cx("form-control", { "is-invalid": isInvalid })}
        ref={(input) => {
          inputRef(input);
          referenceElementRef(input);
        }}
      />
    </div>
  </Hint>
);

const Form = (props) => {
  const now = new Date();
  const store = useStore(state => ({
    location: state.location,
    resource: state.resource,
    service: state.service,
    financier: state.financier,
    event: state.event,
    slot: state.slot,
    update: state.update,
  }));
  const [full, setFull] = useState(true);
  const [consumer, setConsumer] = useState(props.consumer || {});

  const [dirty, setDirty] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [warnings, setWarnings] = useState({});
  const [loading, setLoading] = useState(false);

  const [event, setEvent] = useState({
    consumer: {},
    location: {},
    resource: {},
    service: {},
    financier: {},
    day: props.slot?.day || now.toLocaleDateString("fr-ca"),
    time:
      props.slot?.time ||
      now.toLocaleTimeString("it-IT", { timeStyle: "short" }),
    comments: "",
    instructions: "",
    notify: true,
    compute: true,
    overbooking: false,
    remote: false,
    status: "booked",
    type: props.type
  });

  const query = useQuery(event.id, {
    enabled: !!event.id,
  });

  const consumerQuery = useQuery(window._l(`/${event?.consumer?.id}`).substr(1), {
    enabled: !!event.id && !!event?.consumer?.id && !consumer?.id
  });

  const [mutate, mutation] = useMutation(
    window._l(`/${event.id || "events"}`).substr(1),
    {
      onMutate: (data) => {
        setEvent(data);
      },
      onSuccess: (data) => {
        setEvent(data);
        if (props.onChange) props.onChange(data);
      },
      onError: (error) => {
        window.notify({
          message: error.message,
          type: "danger",
          title: "Error",
        });
      },
    }
  );

  const onClose = () => {
    if (props.onClose) props.onClose();
  };

  const consumers = useFilters(
    {
      key: "consumers",
      sort: { key: "label" },
    },
    "skipLocation"
  );

  let date = new Date()
  if (event?.day && event?.time) {
    date = new Date(`${event.day}T${event.time}`)
  } else if (event?.day) {
    date = new Date(event.day)
  } else if (props.slot?.day && props.slot?.time) {
    date = new Date(`${props.slot.day}T${props.slot.time}`)
  } else if (props.slot?.day) {
    date = new Date(props.slot.day)
  }

  const startsAt = date.getTime()
  const filters = {
    free: true,
    location: event?.location?.id,
    resource: event?.resource?.id,
    service: event?.service?.key,
    // financier: event.financier?.key,
    startsAt,
    endsAt: startsAt + 3 * 24 * 60 * 60 * 1000,
    day: event.day,
    time: event.time
  };

  if (props.day === false) {
    delete filters.day
  }
  if (props.time === false) {
    delete filters.time
  }

  const availability = useQuery(window._l(`/availability`).substr(1), {
    body: filters,
    initialData: {},
  });

  const onChangeConsumer = (array) => {
    if (!array || !array[0]) {
      setConsumer({});
      return setEvent({
        ...event,
        consumer: {},
      });
    }

    const consumer = _onChangeConsumer(array);
    const newEvent = JSON.clone(event);

    const financierKeys = Object.keys(availability.data.facets?.financiers || {});
    const financier =
      consumer.financiers.sort((a, b) => {
        const matchA = financierKeys.find((f) => a.id.includes(f));
        const matchB = financierKeys.find((f) => b.id.includes(f));
        const includesA = financierKeys.includes(a.id);
        const includesB = financierKeys.includes(a.id);
        if (includesA && !includesB) return -1;
        if (!includesA && includesB) return 1;
        if (matchA && !matchB) return -1;
        if (!matchA && matchB) return 1;
        return 0;
      })[0] || {};

    newEvent.financier = {
      key: financier.id,
      identification: financier.identification,
      label: financier.label,
    };

    newEvent.consumer = {
      id: consumer.id,
      label: consumer.label,
    };
    setConsumer(consumer);
    setEvent(newEvent);
  };

  const onChange = ({ target: { name, value, type } }) => {
    const newEvent = JSON.clone(event);
    if (name === "service") {
      newEvent.remote = value?.key
        ? /telemedic|videoconsul/g.test(value?.key)
        : newEvent.remote;
    }
    if (name === "financier") {
      value.key = value.key || value.id;
    }
    if (value && type === "number") {
      set(newEvent, name, +value);
    } else if (type === "checkbox") {
      if (name === "remote" && event.remote === undefined) {
        set(newEvent, name, false);
      } else {
        set(newEvent, name, !get(newEvent, name));
      }
    } else {
      set(newEvent, name, value);
    }
    setEvent(newEvent);
  };

  const toggle = () => {
    if (mutation.isLoading) return;
    const status = event.status === "cancelled" ? "booked" : "cancelled";
    if (status === 'cancelled' && !window.confirm(t('event_cancel_confirmation', '¿Confirmar cancelacion?'))) return
    mutate({ ...event, status });
  };

  const save = async () => {
    if (mutation.isLoading) return;
    mutate(event);
  };

  const reschedule = ({ day, time }) => {
    if (event.day === day && event.time === time && event.status !== "cancelled") {
      return store.update({ reschedule: undefined, event: undefined })
    }
    mutate({ ...event, day, time, status: "booked" })
    store.update({ reschedule: undefined, event: undefined })
  }

  const fetchWarnings = async (consumer) => {
    setLoading(true);
    let warnings = await validateConsumer(consumer);
    warnings = {financiers: warnings.financiers, flag: warnings.financiers?.flag};
    setWarnings(warnings);
    setLoading(false);
  }

  useEffect(() => {
    if (event.type === "lock" && (!event.untilTime || !event.untilDay)) {
      setEvent({
        ...event,
        untilTime: props.isSlot && props.slot?.time ? props.slot?.time : (event.untilTime || "23:59"),
        untilDay: event.day,
      });
    } else if (
      event.type !== "lock" &&
      (!!event.untilTime || !!event.untilDay)
    ) {
      setEvent({
        ...event,
        untilTime: undefined,
        untilDay: undefined,
      });
    }
  }, [event]);

  useEffect(() => {
    if (props.event) {
      setEvent({ ...event, ...props.event });
    }
  }, [props.event]);

  useEffect(() => {
    window.callbacks.consumer = (consumer) => {
      getModal("consumersModal", false).hide();
      if (!consumer) return;
      onChangeConsumer([consumer].filter((p) => p.id));
    };
    return () => {
      delete window.callbacks.consumer;
    };
  });

  useEffect(() => {
    if (props.onClose) {
      window.dispatchEvent(new Event("event.open"));
      window.addEventListener("event.open", props.onClose);
      return () => window.removeEventListener("event.open", props.onClose);
    }
  }, [props.onClose]);

  useEffect(() => {
    const event = JSON.clone(query.data)
    if (query.data?.id && !/^events\/lock-/.test(query.data.id)) {
      setEvent(event);
    } else if (query.data?.id) {
      event.resource.id ||= store.resource?.id
      event.resource.label ||= store.resource?.label
      setEvent(event);
    }
  }, [query.data, store.resource]);

  useEffect(() => {
    const updatedAt = Date.now();
    const payload = JSON.clone(event);
    let eventID =
      props.eventID || new URLSearchParams(location.search).get("eventID");
    eventID = /^events\//.test(eventID) ? eventID : "";
    if (eventID && eventID !== event.id) {
      setEvent({ ...event, id: eventID });
    } else if (!dirty && !event.id) {
      if (!event.location.id && store.location?.id) {
        payload.location = store.location;
        payload.updatedAt = updatedAt;
      }

      if (!event.resource.id && store.resource?.id) {
        payload.resource = store.resource;
        payload.updatedAt = updatedAt;
      }

      if (!event.service.key && store.service?.key) {
        payload.service = store.service;
        payload.updatedAt = updatedAt;
      }

      if (
        !event.financier.key &&
        store.financier?.key &&
        !/\/$/.test(store.financier.key)
      ) {
        payload.financier = store.financier;
        payload.updatedAt = updatedAt;
      }

      payload.type = props.type || "appointment";

      setDirty(true);

      if (event.updatedAt !== payload.updatedAt) {
        setEvent(payload);
      }
    }
  }, [
    event,
    store.event,
    store.slot,
    store.location,
    store.resource,
    store.service,
    store.financier,
    location.search,
    props.eventID,
    props.type,
    dirty,
  ]);

  useEffect(()=>{
    if(consumer?.id){
      fetchWarnings(consumer);
    } else {
      setWarnings({})
    }
  }, [consumer]);

  useEffect(()=>{
    if(consumerQuery.data?.id && !consumer.id){

      setConsumer(consumerQuery.data)
    }
  }, [consumerQuery.data])

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (query.isLoading) return (
    <div className="text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">{t("lodaing")}</span>
      </div>
    </div>
  );

  const isValid = event && event.time && event.day && event.resource?.id && (
    (event.type !== "lock" && event.consumer?.id && event.location?.id && event.service?.key) ||
    (event.type === "lock" && event.untilDay && event?.untilTime));

  availability.financiers = { facets: {}, consumer: { options: [], label: t('consumer_financiers', 'Coberturas del paciente') } };

  {
    const allFinanciers = i18n?.store?.data?.[i18n?.language]?.pms_financiers || {};
    const allFinanciersKeys = Object.keys(allFinanciers).map(key => `pms_financiers:${key}`);
    for (const [key1, financier] of Object.entries(availability.data.facets?.financiers || {})) {
      if (/\/$/.test(key1)) {
        const keys = allFinanciersKeys.filter(key2 => key2.includes(key1) && key2 !== key1);
        for (const key2 of keys) {
          const candidate = allFinanciers[key2.replace("pms_financiers:", "")];
          availability.financiers.facets[key2] = { label: t(key2, candidate), capacity: financier.capacity };
        }
      } else {
        availability.financiers.facets[key1] = {
          ...financier,
          label: t(key1, financier.label),
        }
      }
    }
  }

  if (Array.isArray(consumer.financiers)) {
    for (const financier of consumer.financiers) {
      availability.financiers.consumer.options.push({ key: financier.id, label: t(financier.id, financier.label), capacity: 1 })
    }
  }

  const slots = availability.data.slots || {}
  const facets = availability.data.facets || {}

  return (
    <div className={styles.container}>
      {event.status === 'cancelled' && (
        <div className="text-right mt-n2 mb-2">
          <small className="badge bg-danger">{event.type !== 'lock' ? t('event_cancelled_message', "Turno cancelado") : t('lock_cancelled_message', "Bloqueo anulado")}</small>
        </div>
      )}

      {!event.id && event.type !== "lock" && (
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h5 className="m-0">{t('event_form_title', 'Turno')}</h5>
          <button className={cx('btn btn-sm btn-outline-danger')} onClick={() => {
            onChange({ target: { name: "type", value: "lock" } })
          }}>
            {t('lock_time', "Bloquear horario")}
          </button>
        </div>
      )}
      <div className="mb-2">
        {event.type !== "lock" && (
          <>
            {warnings.financiers?.flag && (
              <div className="bg-warning p-2 rounded mb-2">
                {t('event_warnings_financier', 'El paciente tiene datos incorrectos en la cobertura')}
              </div>
            )}
            <div className="mb-2 position-relative">
              <Typeahead
                selected={[event.consumer].filter((p) => p.id && p.label)}
                isLoading={!!consumers.isLoading}
                isInvalid={!event.consumer?.id || warnings.flag}
                filterBy={() => true}
                inputProps={{
                  id: "consumer",
                }}
                id="consumer"
                allowNew={(results) => !consumers.isLoading}
                onChange={onChangeConsumer}
                onInputChange={(value) => consumers.onChange("q", value)}
                options={consumers.data.flatMap((data) => data)}
                placeholder={t(`consumer_placeholder`, {
                  context: "events",
                })}
                newSelectionPrefix={`${t("new_consumer", {
                  context: "quick_form",
                })}: `}
                emptyLabel={
                  consumers.isLoading ? t(`searching`) : t(`no_matches`)
                }
                paginationText={t(`display_more_results`)}
                promptText={t(`type_to_search`)}
                searchText={t(`searching`)}
                renderMenuItemChildren={(option, props, index) => {
                  const items = [
                    option.identification,
                    ...option.emails.map((e) => e.value),
                  ];
                  return (
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between">
                        <div className="flex-fill w-100">{option.label}</div>
                        <div className="overflow-hidden text-truncate">
                          {option.financiers.length > 0 && (
                            <div className="text-secondary ms-2 me-n3">
                              {option.financiers.map((item) => (
                                <small
                                  className="me-2"
                                  key={`${option.id}${item.id}${item.identification}`}
                                >
                                  {[t(item.id, item.label), item.identification]
                                    .filter(Boolean)
                                    .join(" #")}
                                </small>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                      {items.length > 0 && (
                        <div className="text-secondary mt-n1 text-truncate overflow-hidden">
                          {items.filter(Boolean).map((item) => (
                            <small className="me-2" key={`${option.id}${item}`}>
                              {item}
                            </small>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                }}
                renderInput={renderInput({
                  isInvalid: !event.consumer?.id,
                })}
              />
              {event.consumer.id && (
                <div className="form-control-actions d-flex align-items-center">
                  <button
                    className="btn btn-sm p-0 btn-link"
                    type="button"
                    data-consumer={JSON.stringify({
                      id: event.consumer.id,
                    })}
                    data-bs-toggle="modal"
                    data-bs-target="#consumersModal"
                  >
                    {t("edit", { context: "consumer" })}
                  </button>
                </div>
              )}
            </div>
          </>
        )}

        <div className="d-grid gap-2 mb-2">
          <Resources
            onChange={value => onChange({ target: { name: "resource", value: value || {} } })}
            store="manual"
            value={event.resource.id ? { id: event.resource.id, label: event.resource.label } : null}
            facets={facets.resources || {}}
            highlight={!event.resource?.id}

          />
          {event.type !== "lock" && (
            <>
              <Services
                isLoading={availability.isFetching}
                onChange={value => onChange({ target: { name: "service", value: value || {} } })}
                store="manual"
                value={event.service.key ? { key: event.service.key, label: event.service.label } : null}
                facets={facets.services || {}}
                highlight={!event.service?.key}
              />
              <Financiers
                isLoading={availability.isFetching}
                onChange={value => onChange({ target: { name: "financier", value: value || {} } })}
                store="manual"
                value={event.financier.key ? { key: event.financier.key, label: event.financier.label } : null}
                facets={availability.financiers.facets}
                top={availability.financiers.consumer}
              />
              <Locations
                isLoading={availability.isFetching}
                onChange={value => onChange({ target: { name: "location", value: value || {} } })}
                store="manual"
                value={event.location.id ? { id: event.location.id, label: event.location.label } : null}
                facets={facets.locations || {}}
                highlight={!event.location?.id}
              />
              {event.id && event.token && event.remote && (
                <div className="text-end">
                  <a
                    href={`${REACT_APP_MEET_URL}/${event.id}/${event.token}`}
                    target={kebabCase(`meet-${event.id}-${event.token}`)}
                    className="small link-info my-1"
                  >
                    {t("open_telemedicine_link", 'Abrir videoconsulta')}
                  </a>
                </div>
              )}
            </>
          )}
        </div>

          <>
            {event.type === 'lock' && (
              <label className="form-label">{t('start_end_date_and_time', 'Fechas de inicio y fin')}</label>
            )}
            <div className="d-flex align-items-center gap-2 mb-2">
              <input
                name="day"
                id="day"
                minLength="6"
                maxLength="8"
                type="date"
                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                placeholder={t("day_placeholder", {
                  context: "events",
                })}
                className={cx("form-control flex-fill", {
                  "is-invalid": props.day !== false && (!event.day || ((!event.id && event.day !== props.slot?.day) && ((!Object.values(slots?.[event?.day] || {}).some(value => value.capacity)) && event.type !== 'lock'))),
                })}
                value={event.day || ""}
                onChange={onChange}
              />

              <input
                step="300"
                pattern="[0-9]{2}:[0-9]{2}"
                required
                type="time"
                style={{ width: '150px !important' }}
                name="time"
                id={`time`}
                value={event.time || ""}
                onChange={onChange}
                className={cx("form-control", {
                  "is-invalid": props.time !== false && (!event.time || ((!event.id && event.day !== props.slot?.day && event.time !== props.slot?.time) && (!slots?.[event?.day]?.[event?.time]?.capacity && event.type !== 'lock'))),
                })}
                placeholder={t("time_placeholder", {
                  context: "events",
                })}
              />
            </div>

            {event.type === "lock" && (
              <div className="mb-2">
                <div className="d-flex align-items-center gap-2">
                  <input
                    data-place="bottom"
                    minLength="6"
                    maxLength="8"
                    type="date"
                    pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                    name="untilDay"
                    id="eventUntilDay"
                    value={event.untilDay || ""}
                    onChange={onChange}
                    className={cx("form-control flex-fill", {
                      "is-invalid": !event.untilDay,
                    })}
                  />
                  <input
                    data-place="bottom"
                    step="300"
                    pattern="[0-9]{2}:[0-9]{2}"
                    required
                    type="time"
                    style={{ width: '150px !important' }}
                    name="untilTime"
                    id="eventUntilTime"
                    value={event.untilTime || ""}
                    onChange={onChange}
                    className={cx("form-control", {
                      "is-invalid": !event.untilTime,
                    })}
                  />
                </div>
              </div>
            )}
          </>
      </div>

      {event.type !== 'lock' && (event.id || (props.reschedule && event.resource.id && event.location.id)) && (
        <div className="mb-2 btn-light border rounded">
          <button className="btn btn-sm p-2 text-secondary w-100 text-start d-flex align-items-center justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSlots" aria-expanded="false" aria-controls="collapseSlots">
            <div>{event.id ? (
              t('reschedule', 'Reprogramar')
            ) : (
              t('times_slots', 'Horarios disponibles')
            )}</div>
            <div><IconPlusCircle width="16" height="16" className="text-secondary opacity-75"/></div>
          </button>
          <div className="collapse border-top p-2" id="collapseSlots">
            <div className="d-flex flex-column gap-3">
              {Object.keys(slots).sort().map(day => (
                <div key={day}>
                  <div className={cx("small text-secondary text-first-up mb-1 lh-1", { "fw-bold": day === event.day })}>
                    {formatDate(day, "eeee dd - MMMM yyyy")}
                  </div>
                  <div className="d-flex flex-nowrap gap-2 overflow-auto">
                    {Object.entries(slots[day]).filter(([time, entry]) => entry.capacity).sort().map(([time, entry]) => (
                      <button
                        key={`${day}${time}`}
                        type="button"
                        onClick={(e) => setEvent({ ...event, day, time })}
                        className="btn btn-sm btn-outline-primary"
                      >{time}</button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {event.id && (
              <button
                type="button"
                disabled={mutation.isLoading}
                className={cx("btn btn-sm btn-warning text-dark mt-3")}
                onClick={() => {
                  store.update({
                    event, reschedule,
                    resource: event.resource,
                    location: event.location,
                    service: event.service,
                    date: event.day,
                  })
                  if (location.pathname.includes('/events/agenda/')) {
                    navigate(location.pathname.replace('/agenda/', '/calendar/'), { replace: true })
                  }
                  onClose()
                }}
              >
                {t("reschedule_from_calendar", "Reprogramar desde el calendario")}
              </button>
            )}
          </div>
        </div>
      )}

      <div className="btn-light border rounded">
        <button className="btn btn-sm p-2 text-secondary w-100 text-start d-flex align-items-center justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOptions" aria-expanded="false" aria-controls="collapseOptions">
          <div>{t('more_options', 'Mas opciones')}</div>
          <div><IconPlusCircle width="16" height="16" className="text-secondary opacity-75"/></div>
        </button>

        <div className="collapse border-top p-2" id="collapseOptions">
          {event.type !== "lock" && (
            <Text
              className="mb-2"
              floating={false}
              id="comments"
              name="comments"
              value={event.comments || ""}
              onChange={onChange}
              placeholder={t("internal_note", { context: "events" })}
              rows={1}
            />
          )}

          <Text
            className="mb-2"
            floating={false}
            id="instructions"
            name="instructions"
            value={event.instructions || ""}
            onChange={onChange}
            placeholder={t("consumer_message", {
              context: "events",
            })}
            rows={1}
          />

          {event.type !== "lock" && (
            <Select
              className="mb-2"
              floating={false}
              required
              id="eventStatus"
              name="status"
              value={event.status || "booked"}
              onChange={onChange}
              placeholder={t("status", { context: "events" })}
              options={[
                "booked",
                "arrived",
                "active",
                "fulfilled",
                "noshow",
                "cancelled",
                "pending",
              ].map((value) => ({
                value,
                label: t(`status_${value}`, { context: "events" }),
              }))}
            />
          )}

          <div className="form-check form-switch" data-tip={t("notify_tip", { context: "events" })} data-for={event.type === "lock" ? "lock-data-tip" : undefined}>
            <input
              className="form-check-input"
              type="checkbox"
              id="notify"
              name="notify"
              checked={event.notify ?? true}
              onChange={onChange}
            />
            <label className="form-check-label" htmlFor="notify">
              {t("notify", { context: "events" })}
            </label>
          </div>

          {event.type !== "lock" && (
            <div className="form-check form-switch" data-tip={t("compute_tip", { context: "events" })}>
              <input
                className="form-check-input"
                type="checkbox"
                id="compute"
                name="compute"
                checked={event.compute ?? true}
                onChange={onChange}
              />
              <label className="form-check-label" htmlFor="compute">
                {t("compute", { context: "events" })}
              </label>
            </div>
          )}

          {event.type === "lock" ? (
            <div className="form-check form-switch" data-tip={t("event_cancellable_tip")} data-place="bottom" data-for="lock-data-tip">
              <input
                className="form-check-input"
                type="checkbox"
                name="cancellable"
                checked={!!event.cancellable}
                id="checkEventCancellable"
                onChange={onChange}
              />
              <label
                className="form-check-label"
                htmlFor="checkEventCancellable"
              >
                {t("cancellable")}
              </label>
            </div>
          ) : event.type !== "lock" && (
            <div className="form-check form-switch" data-tip={t("remote_tip", { context: "events" })}>
              <input
                className="form-check-input"
                type="checkbox"
                id="remote"
                name="remote"
                checked={event.remote || false}
                onChange={onChange}
              />
              <label className="form-check-label" htmlFor="remote">
                {t("remote", { context: "events" })}
              </label>
            </div>
          )}
          <ReactTooltip id="lock-data-tip" effect="solid"/>

          {event.type !== "lock" && (
            <div
              className="form-check form-switch"
              data-tip={t("overbooking_tip", { context: "events" })}
            >
              <input
                className="form-check-input"
                type="checkbox"
                id="overbooking"
                name="overbooking"
                checked={event.overbooking || false}
                onChange={onChange}
              />
              <label className="form-check-label" htmlFor="overbooking">
                {t("overbooking", { context: "events" })}
              </label>
            </div>
          )}
        </div>
      </div>

      <hr className="mx-n3" />

      <div className="d-flex align-items-center justify-content-between">
        <button type="button" className="btn link-secondary py-0" onClick={onClose}>
          {t("close", { context: "events" })}
        </button>
        {loading ? (
          <span
            className="spinner-border spinner-border-sm me-1"
            role="status"
            aria-hidden="true"
          ></span>
        ):
          !isValid ? (
            <div className="alert alert-warning py-1 mb-0">
              {t("event_missing_data_short", "Campos requeridos")}
            </div>
          ) : !isEqual(query.data, event) && !/^events\/lock-/.test(event.id) ? (
            <button
              type="button"
              disabled={mutation.isLoading}
              className="btn link-primary py-0 ms-2"
              onClick={save}
            >
              {mutation.isLoading && (
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {t("save", { context: "events" })}
            </button>
          ) : event.id && (
            <button
              type="button"
              disabled={mutation.isLoading}
              data-tip={event.status !== "cancelled" ? t('event_cancel_tip', 'Cancela el turno') : t('event_restore_tip', 'Reconfirma el turno')}
              className={cx("ms-auto btn py-0", {
                "link-danger": event.status !== "cancelled",
                "link-success": event.status === "cancelled",
              })}
              onClick={toggle}
            >
              {event.status !== "cancelled" ? (
                t("cancel", {
                  context: ["events", event.type || "appointment"].filter(Boolean).join("_"),
                })
              ) : (
                t("reschedule", {
                  context: ["events", event.type || "appointment"].filter(Boolean).join("_"),
                })
              )}
            </button>
          )}
      </div>
    </div>
  );
};

export default Form;
